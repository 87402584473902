import React from "react"
import styled from 'styled-components';
import { PageProps } from "gatsby"
import { renderRichText } from 'gatsby-source-contentful/rich-text'

export type PlainPageProps = {
  page: {
    title: string
    body: {
      raw: string
    }
  }
};


const Container = styled.div`
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2em;
  padding-bottom: 5em;
  font-size: 16px;
  
  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  b {
    font-weight: bold;
  }

  h1 {
    text-align: center;
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h2 {
    text-align: center;
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h3 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  li {
    display: list-item;
    text-align: match-parent;
  }

  ul, menu, dir {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-inline-start: 40px;
  }
`;

const options = {};

const Page: React.FC<PageProps<object, PlainPageProps>> = (props) => {
  const { pageContext } = props;
  const { page } = pageContext;

  return <Container>{renderRichText(page.body, options)}</Container>
}


export default Page